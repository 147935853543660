import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const SecondaryBtn = styled(BaseButton)`
	background: transparent;
	border-color: transparent;
	color:#000000;
	position:relative;
	font-size:12px;
	text-align:left;
	padding-left:0;
	font-weight:900;
	.text{
			text-decoration:none;
			position:relative;
			color:#529A00;
&:after, 
&:before{
content:'';
height:2px;
background:#529A00;
position:absolute;
display:block;
 bottom:-2px;
 width:100%;
}
&:before{
 background:#000;
 transform: scaleX(0);
transform-origin: left center;
 transform:scaleX(0);
 z-index:1;
transition: all 0.4s ease 0s;
}
			& + .icon{
				margin-left:0;
				width:28px;
			}
		}
	& .icon{
		background: transparent;
		margin-left:0;
		display: inline-block;
    vertical-align: middle;
		position:relative;
		z-index:1;
		
		> svg{
			fill:#223C7E;
		}
	}
	&:hover{	
		color:#000;
		.text{
			color:#000;
			&:before{
		   transform:scaleX(1);
			 
			}
		}
		& .icon{
			background: transparent;
			> svg{
				fill:#223C7E;
			}
		}
	}
`

const SecondaryLinkButton = (props) => {
	const { icon, text, iconBefore } = props;
	return (
		<SecondaryBtn className='btn'>
		{iconBefore === true && icon && <span className='icon'>{icon}</span>}
			<span className='text'>{text}</span>
			{iconBefore === false && icon && <span className='icon'>{icon}</span>}
		</SecondaryBtn>
	)
}

export default SecondaryLinkButton