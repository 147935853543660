import React, { Component } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Section, Tags, ComingSoon  } from '../Section'
import { Link } from 'gatsby'
import PrimaryLinkButton from "../Button/PrimaryLinkButton";
import SecondaryLinkButton from "../Button/SecondaryLinkButton";
import { StaticImage } from "gatsby-plugin-image"
import BreakpointDown from "../Media/BreakpointDown";

const SlickSlider = styled.div`
  max-width: 100%;
  position: relative;
  .slick-slider {
    .slick-arrow {
      display: none !important;
    }
  }
`;
const SlickItem = styled.div`
  display: block !important;
  outline: none;
  max-width: 100%;
  font-size: 18px;
  padding: 20px 15px 140px;
`;

const Card = styled.div`
  padding: 70px 50px ;
  background:#fff;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);
  /* @media (min-width:2200px){
    padding: 20px 15px;
  } */
  @media(max-width:1440px){
    padding: 20px 14px; 
  }
  ${BreakpointDown.lg`
   text-align:center;
 `}
  .btn{
    margin-top:15px;
  }
`
const CardTitle = styled.div`
 margin-bottom:6px;
 font-weight:bold;
`
const CardImg = styled.div`
  position: relative;
  max-width:100%;
  width:100%;
  margin-top:26px;
  .gatsby-image-wrapper{
    width:100%;
  }
`
const Date = styled.div`
  color:#666666;
  font-size:10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media (min-width:2200px){
    font-size:14px;
  }
`
const SectionHeader = styled.div`
 text-align:center;
 .h1{ margin-bottom:0px;}
 ${BreakpointDown.md`
 p{
   margin-bottom:0;
 } 
 `}
`
const Title = styled.h2`

`
const BtnWrapper = styled.div`
 text-align:center;
 margin-top:-120px; 
`

export default class LetastArticles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSlider:false
    }
  }
  render() {
    const settings = {
      centerMode: true,
      infinite: true,
      centerPadding: "80px",
      slidesToShow: 4,
      slidesToScroll: 1,
      speed: 1000,
      autoplaySpeed: 2000,
      autoplay: true,
     
      responsive: [
        {
          breakpoint: 2200,
          settings: {
            slidesToShow: 4,
            arrows: false,
          },
        },
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 3,
            arrows: false,
          },
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
            arrows: false,
          },
        },
        {
          breakpoint: 1180,
          settings: {
            slidesToShow: 2,
            arrows: false,
            centerMode: true,
            centerPadding: "60px",
            infinite: true,
          },
        },
        {
          breakpoint: 1180,
          settings: {
            slidesToShow: 2,
            arrows: false,
            centerMode: true,
            centerPadding: "60px",
            infinite: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            arrows: false,
            centerMode: true,
            centerPadding: "10px",
            infinite: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            arrows: false,
            centerMode: false,
            centerPadding: "10px",
            infinite: true,
          },
        },
      ],
    };
    return (
      <Section pt="80px" mpt="40px" bgColor="#F8F8F8" pb="50px" mpb="40px" xxpt="100px" xxpb="100px">
        <div className="container">
          <SectionHeader>
            <Title className="h1">Latest Articles & Blogs</Title>
            {/* <p>Lorem Ipsum is simply dummy text of the printing and type setting industry.</p> */}
          </SectionHeader>
          {this.state.isSlider &&
            <><SlickSlider>
            <Slider {...settings} ref={(c) => (this.slider = c)}>
              {[1, 2, 3, 4, 5, 6, 7].map((item, index) => (
                <SlickItem key={index}>
                  <Card>
                    <Date>24 June, 2020</Date>
                    <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                    <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                    <CardImg>
                      <StaticImage
                        src="../../images/article-1.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <Link to="/"><SecondaryLinkButton text="View Full Detail" /></Link>
                  </Card>
                </SlickItem>
              ))}
            </Slider>
          </SlickSlider>
          <BtnWrapper>
            <Link to="/"><PrimaryLinkButton text="View All Articles" /></Link>
          </BtnWrapper>
            </>
          }
          {!this.state.isSlider && <ComingSoon textAlign="center">Coming Soon...</ComingSoon>}
        </div>
      </Section> 
    );
  }
}
